import React from "react";
import banner from "./assets/banner.png";
import formBanner from "./assets/form_banner.png";
import biren from "./assets/biren.png";
import ContactForm from "./ContactForm";
import Navigation from "./Navigation";

import { MapIcon, CheckIcon } from "@heroicons/react/outline";

const EYE_SERVICES = [
  "Annual Eye Exam",
  "Custom Prescription Glasses",
  "Custom Sunglasses",
  "Low/Blurry Vision",
  "Cataracts",
  "Glaucoma",
  "Ocular Disease",
  "Chronic Dry Eyes",
  "Eye Infection",
  "Diabetic Retinopathy",
  "Amblyopia (lazy eye)",
  "Hypertensive Retinopathy",
];

const INSURANCES = [
  "Medicare",
  "Blue Cross Blue Shield (BCBS)",
  "Humana",
  "Cigna",
  "United Healthcare",
  "Aetna",
];

export default function Home() {
  return (
    <>
      <Navigation />

      <div>
        <div className="mb-4 relative font-montserrat md:bg-main-gray-100 md:pb-8">
          <img className="" src={banner} alt="Banner" />
          <div className="flex flex-col md:block mdp:absolute top-[20%] left-[5%] 2xl:top-[30%] md:text-center">
            <div className="text-main-blue-600 uppercase font-bold tracking-widest mb-4 2xl:text-xl md:mt-8">
              Mobile Eye Care
            </div>
            <div className="text-4xl font-light 2xl:text-5xl lg:text-2xl">
              Service that'll make you
            </div>
            <div className="text-4xl font-black mb-8 2xl:text-5xl lg:text-2xl">
              see us with new eyes
            </div>
            <div className="italic mdp:w-[300px] 2xl:text-lg md:w-full">
              Servicing nursing homes, assisted & independent living homes in
              Illinois
            </div>
            <div className="md:hidden text-4xl text-main-blue-600 absolute top-[95px] font-bold left-[236px] lg:text-2xl lg:top-[85px] lg:left-[160px] 2xl:top-[110px] 2xl:left-[315px] 2xl:text-5xl">
              __________
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center py-8 gap-4 w-full *:font-montserrat">
          <div className="flex flex-col justify-center items-center *:uppercase mb-4 text-center">
            <div className="text-md font-bold text-main-blue-600 tracking-wider mb-2">
              Our Services
            </div>
            <div className="text-4xl font-light text-main-gray-600 md:text-3xl">
              A Wide Range of
            </div>
            <div className="text-5xl font-black text-main-gray-600 md:text-3xl">
              Optometry Services.
            </div>
          </div>
          <div className="grid grid-cols-3 md:grid-cols-2 gap-4 mb-4">
            {EYE_SERVICES.map((service) => (
              <div className="flex gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-main-blue-600" />
                <span className="text-main-gray-600 font-bold">{service}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex sm:flex-col p-8 justify-center items-center gap-4 w-full *:font-montserrat bg-main-gray-100">
          <div className="w-[50%]">
            <img src={biren} alt="Logo" />
          </div>
          <div className="w-[50%] sm:w-[90%]">
            <div className="flex flex-col justify-center items-center text-center mb-4">
              <div className="text-md font-bold text-main-blue-600 tracking-wider mb-2">
                OUR MISSION
              </div>
              <div className="text-5xl font-black text-main-gray-600 mb-8 lg:text-3xl">
                EYECARE AT YOUR CONVENIENCE.
              </div>
              <div className="text-2xl font-light text-main-gray-600 mb-8 lg:text-lg">
                At Enroute Eyecare, our mission is to provide a commitment to
                patient-centered care, delivering personalized and compassionate
                treatment in a convenient setting.
              </div>

              <div className="text-md font-bold text-main-gray-600">
                - Biren Patel, OD
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center py-8 gap-4 w-full *:font-montserrat">
          <div className="flex flex-col justify-center items-center *:uppercase mb-4">
            <div className="text-md font-bold text-main-blue-600 tracking-wider mb-2">
              Insurance
            </div>
            <div className="text-5xl font-black text-main-gray-600 md:text-3xl text-center">
              Most Insurances Accepted.
            </div>
          </div>
          <div className="mb-4">
            {INSURANCES.map((service) => (
              <span className="flex gap-2 items-center">
                <CheckIcon className="h-5 w-5 text-main-blue-600" />
                <span className="text-main-gray-600 font-bold">{service}</span>
              </span>
            ))}
          </div>
        </div>

        <div className="mb-4 relative">
          <div>
            <img
              className="w-full object-cover md:h-[200px]"
              src={formBanner}
              alt="Banner"
            />
          </div>
          <div className="flex flex-col justify-center items-center py-8 gap-4 w-full *:font-montserrat absolute top-0">
            <div className="flex flex-col justify-center items-center *:uppercase mb-4">
              <div className="text-md font-bold text-main-blue-100 tracking-wider mb-2">
                Interest Form
              </div>
              <div className="text-4xl font-light text-white md:text-3xl">
                Now Accepting
              </div>
              <div className="text-5xl font-black text-white md:text-3xl">
                New Patients.
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full bg-main-gray-100 h-[500px]"></div>

          <div className="flex flex-col w-[1000px] lg:w-full justify-center items-center py-8 gap-4 *:font-poppins absolute bg-white top-[200px] m-auto right-0 left-0 text-center">
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
}
